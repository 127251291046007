import React from "react";
import { useRecoilState } from "recoil";
import { currentUserAtom } from "../App";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

import expenses from "../img/expenses.png";

const About = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useRecoilState(currentUserAtom);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  const handleClick = () => {
    navigate("/dashboard");
  };

  return (
    <div>
      <CssBaseline />
      <IconButton
        onClick={handleClick}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "black",
        }}
      >
        <HomeIcon fontSize="large" />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "20px",
        }}
      >
        <Box className="header">
          <header>
            <h1>About Us - Sri Lakshmi Venkataramana Temple</h1>
          </header>
        </Box>
        <Box>
            <p>
                <center>
          <h2>
            The restoration work of the temple is in progress. Donate generously
            for this noble cause. 
            <br></br>ದೇವಾಸ್ಥಾನದ ಜೀರ್ಣೋದ್ಧಾರದ ಕಾಮಗಾರಿ ಪ್ರಗತಿಯಲ್ಲಿದೆ
            . ಉದಾರವಾಗಿ ಧನಸಹಾಯ ಮಾಡಿ ಸಹಕರಿಸಿರಿ.
          </h2>
          </center>
          </p>
          <p>
            <h2>ಸ್ಥಳ ಪುರಾಣ</h2>
            ಸ್ಥಾಪಕರಾದ ನಾರಾಯಣ ಭಟ್ ಮರಾಠೇ ಯವರು ವೆಂಕಟರಮಣನ ಮಹಾ ಭಕ್ತರಾಗಿದ್ದರು . ಅವರು
            ಕಾಲ್ನಡಿಗೆಯಲ್ಲಿ ದುರ್ಗ ಗ್ರಾಮ , ಕಾರ್ಕಳ ತಾಲೂಕಿನಿಂದ ತಿರುಪತಿಗೆ ದರ್ಶನ ಮಾಡಲು
            ಆಗಾಗ್ಯೆ ಪ್ರಯಾಣ ಮಾಡುತಿದ್ದರು. ಅವರಿಗೆ ಮಲೆನಾಡಿನಲ್ಲಿ ವೆಂಕಟರಮಣ ದೇವಾಲಯ
            ಸ್ಥಾಪಿಸುವ ಆಲೋಚನೆ ಬಂದು, ಇಲ್ಲಿ ದುರ್ಗಾ ಗ್ರಾಮದ ಪ್ರಶಾಂತ ವಾತಾವರಣದಲ್ಲಿ ಅವರ
            ಕನಸನ್ನು ನನಸು ಮಾಡಿದರು ಎಂದು ಪ್ರತೀತಿ ಇದೆ. ಈ ದೇವರ ಮೂಲ ವಿಗ್ರಹವು ಸುಮಾರು 2
            ಅಡಿ ಎತ್ತರವಿದ್ದು, ತಿರುಮಲ ವೆಂಕಟರಮಣ ವಿಗ್ರಹವನ್ನು ಹೋಲುತ್ತದೆ. ಈ ಕಾರಣಗಳಿಂದ
            ಇಲ್ಲಿಯ ಕ್ಷೇತ್ರ ಕಿರು ತಿರುಪತಿ ಎಂದು ಕರೆಯಲ್ಪಡುತ್ತದೆ .
          </p>
          <p>
            <h2>ದೇವಸ್ಥಾನದ ಬಗ್ಗೆ</h2>
            ಭಗವಾನ್ ಮಹಾವಿಷ್ಣುವಿನ ಏಳನೇಯ ಅವತಾರವಾದ ಪರಶುರಾಮ ಸೃಷ್ಟಿಎಂದೇ ಖ್ಯಾತವಾದ,
            ದೇವಾಲಯಗಳಿಂದ ಪುನೀತವಾದ ಮಲೆನಾಡಿನ ಈ ಪ್ರದೇಶ ಕಾರ್ಕಳ ತಾಲೂಕಿನ ದುರ್ಗ
            ಗ್ರಾಮದಲ್ಲಿದ್ದು ನೈಸರ್ಗಿಕ ಸಿರಿ ಸಂಪತ್ತಿನಿಂದ ಕೂಡಿದ ಶಾಂತ ಪ್ರದೇಶವಾಗಿದೆ.
            ಪುಂಡಾಜೆ ಎಂಬಲ್ಲಿ 1864 ರಲ್ಲಿ ಶ್ರೀ ನಾರಾಯಣ ಭಟ್ ಮರಾಠೇ ಎಂಬವರಿಂದ
            ಸ್ಥಾಪಿಸಲ್ಪಟ್ಟ ಈ ದೇವಾಲಯವು ತನ್ನ ಮಹಿಮಾತಿಶಯಗಳಿಂದ ತನ್ನ ಆರಾಧಕ ಭಕ್ತರನ್ನು
            ಅನುಗ್ರಹಿಸುತ್ತಾ ಪ್ರಸಿದ್ದಿ ಪಡೆದಿದೆ. ಶ್ರೀ ಲಕ್ಷ್ಮಿ ವೆಂಕಟರಮಣ ದೇವರ
            ಪ್ರತಿಷ್ಠಾಪನೆಯು ಹಾಗು ಕಾರ್ಕಳ ಶ್ರೀ ವೆಂಕಟರಮಣ ದೇವರ ಪುನರ್ ಪ್ರತಿಷ್ಠಾಪನೆಯು
            ಒಂದೇ ದಿನ ಅವಧೂತ ಅಪ್ಪಾ ಶಾಸ್ತ್ರೀ ದಾಮ್ಲೆ ಯವರು ನೆರವೇರಿಸಿದ್ದರೆಂದು ಪ್ರತೀತಿ
            ಇದೆ. ಬೇರೆ ಬಹುತೇಕ ಕಡೆ ಶ್ರೀ ವೆಂಕಟರಮಣ ದೇವರ ವಿಗ್ರಹವು ಚರವಾಗಿದ್ದು, ಇಲ್ಲಿ
            ಬಹಳ ಅಪರೂಪವಾಗಿ ಸ್ಥಿರವಾದ ಶಿಲಾಮೂರ್ತಿಯು ತುಂಬಾ ವಿಶೇಷ ಪ್ರಾಧಾನ್ಯತೆ ಪಡೆದು ,
            ಕಿರು ತಿರುಪತಿ ಎಂದು ಪ್ರಸಿದ್ಧಿ ಪಡೆದಿದೆ. ಸ್ಥಾಪಕರು ದುರ್ಗ, ಮೆಲೆಬೆಟ್ಟು ,
            ಮಾಳ , ಫಂಡಿಜೆ , ಮುಂಡಾಜೆಗಳಲ್ಲಿ ಇದ್ದ ಎಲ್ಲಾ ಸುಮಾರು 300 ಎಕ್ರೆ ಆಸ್ತಿಗಳನ್ನು
            ಶ್ರೀ ದೇವರ ಹೆಸರಿಗೆ ನೋಂದಾಯಿಸಿದ್ದರು. ಸ್ಥಾಪಕರ ವಂಶಸ್ಥರು ದೇವಸ್ತಾನದ
            ಪರಿಸರದಲ್ಲಿ ಇದ್ದು ಆಢಳಿತದ ಹೊಣೆಯನ್ನು ನಿರ್ವಹಿಸುತ್ತಿದ್ದರು. ಸಂಸ್ಥಾಪಕರ
            ವಂಶಸ್ಥರಾದ್ದರಿಂದ ದೇವಳದ ಆಢಳಿತ ಮರಾಠೇ ವಂಶಸ್ಥರಲ್ಲಿ ಹಿರಿಯ ವ್ಯಕ್ತಿಗೆ
            ಪಾರಂಪರ್ಯವಾಗಿ ಬಂದಿದ್ದು , ದೇವಳದ ಸುಪರ್ದಿಕೆ ಅಥವಾ ಕೇರ್ ಟೇಕರ್ ಆಗಿರುತ್ತಾರೆ.
            ಈ ದೇವಳವು ತನ್ನದೇ ಆಸ್ತಿಯಲ್ಲಿ ಇರುವುದರಿಂದ, ಇದು ಯಾವುದೇ ಒಂದು ಕುಟುಂಬದ ಕುಲ
            ದೇವರು ಅಥವಾ ಮನೆ ದೇವರು ಅಲ್ಲವಾಗಿದ್ದು , ಎಲ್ಲಾ ಆಸ್ತಿಕ ಬಂಧುಗಳಿಗೆ ಸೇರಿದೆ.
            ಶ್ರೀ ದೇವರ ಆಸ್ತಿಗಳಿಂದ ಬಂದ ಆದಾಯದಿಂದ ದೇವಾಲಯದ ನಿತ್ಯದ ಆಗುಹೋಗುಗಳಿಗೆ
            ಸಾಕಷ್ಟು ಆದಾಯ ಬರುತ್ತಿತು . ಹಾಗಾಗಿ ದೇವಸ್ಥಾನದಲ್ಲಿ ತ್ರಿಕಾಲ ಪೂಜೆ , ವಾದ್ಯ
            ಸಮೇತ ಉತ್ಸವ , ಅನ್ನ ಸಂತರ್ಪಣೆಗಳಿಂದ ವೈಭವೋಪೇತವಾಗಿ ನಡೆಯುತ್ತಿದ್ದವು. ಈ ಹಿಂದೆ
            ಶ್ರೀ ದೇವರ ಉತ್ಸವಕ್ಕೆ ನಿರ್ಮಿಸಲಾಗಿದ್ದ ರಥವನ್ನು ಕಾರಣಾಂತರದಿಂದ ಕಾರ್ಕಳ ಶ್ರೀ
            ಅನಂತ ಶಯನ ದೇವಸ್ಥಾನಕ್ಕೆ ದಾನ ಮಾಡಲಾಗಿದೆ. ಕಾಲಾನಂತರದಲ್ಲಿ ಬಂದ ಭೂಸುಧಾರಣೆ
            ಕಾನೂನಿನಿಂದಾಗಿ ಜಮೀನುಗಳು ಒಕ್ಕಲುಗಳ ಅಧೀನವಾದವು . ಈಗ ದೇವಸ್ಥಾನದ ಅಧೀನ ಕೇವಲ
            26 ಸೆಂಟ್ಸ್ ಜಾಗ ಉಳಿದುಕೊಂಡಿದೆ. ಒಕ್ಕಲುಗಳಿಂದ ಬರುತ್ತಿದ್ದ ಆದಾಯ ಕುಂಠಿತವಾಗಿ
            ದೇವಸ್ಥಾನದ ಆಗುಹೋಗುಗಳು , ದುರಸ್ತಿ ಕಾರ್ಯ ಕಾಲಕಾಲಕ್ಕೆ ನಡೆಯದೇ ಜೀರ್ಣಾವಸ್ಥೆ
            ಹೊಂದಿದೆ . 1994-95 ರಲ್ಲಿ ಹೇಗೋ ಬಹು ಪ್ರಯತ್ನದಿಂದ ದುರಸ್ತಿ ಕಾಮಗಾರಿ ನಡೆದು
            1995 ಮೇ 14 ರಂದು ಶ್ರೀ ದೇವಳದ ಕಲಶ ಪ್ರತಿಷ್ಠಾಪನೆಗೊಂಡಿತ್ತು. ಇದಾಗಿ
            ಹತ್ತಿಪ್ಪತು ವರ್ಷಗಳೇ ಸಂದುದರಿಂದ ಪ್ರಸ್ತುತ ದೇಗುಲವು ಜೀರ್ಣಾವಸ್ಥೆಗೆ ಬಂದು
            ತುರ್ತಾಗಿ ನವೀಕರಣ ಅಪೇಕ್ಷಿಸುತ್ತದೆ. ಮಹಾ ವೈಭವದಿಂದ ಮೆರೆದ ಈ ದೈವ ಸನ್ನಿಧಿಯು
            ಶಕ್ತಿ ಕೇಂದ್ರವಾಗಿ ಬೆಳೆಯಬೇಕೆಂಬ ಆಶಯದಿಂದ ಸಂಬಂಧಪಟ್ಟ ವಂಶಸ್ಥರು , ಮಾಜಿ
            ಒಕ್ಕಲುಗಳು ಮತ್ತು ಭಕ್ತ ಭಜಕ ವೃಂದದವರು ಯೋಚಿಸಿ ಈ ಬಗ್ಗೆ ಕಾರ್ಯೋನ್ಮುಖರಾದರು .
            2019 ರಲ್ಲಿ ಈ ಬಗ್ಗೆ ಅಷ್ಟಮಂಗಲ ಪ್ರಶ್ನಾವಳಿ ನಡೆಸಿ, ಜೀರ್ಣೋಧಾರಕ್ಕೆ ಬೇಕಾದ
            ಪ್ರಾಯಶ್ಚಿತ್ತ ಮಾರ್ಗಸೂಚಿಗಳನ್ನು ಪಡೆದು ಅದರಂತೆ ಎಲ್ಲಾ ಪ್ರಾಯಶ್ಚಿತ್ತ
            ಕರ್ಮಗಳನ್ನು ಹಂತ ಹಂತವಾಗಿ 2023 ರೊಳಗೆ ನೆರವೇರಿಸಲಾಗಿದೆ . ಇದರ ವೆಚ್ಚಕ್ಕೆ
            ರೂಪಾಯಿ 10 ಲಕ್ಷ ನಿಧಿಯನ್ನು ಭಕ್ತರಿಂದ ಸಂಗ್ರಹಿಸಿ ವಿನಿಯೋಗಿಸಲಾಗಿದೆ.
            ದೇವಸ್ಥಾನದ ಸ್ಥಾಪಕ ವಂಶಸ್ಥರು, ಒಕ್ಕಲು, ಭಜಕ ಭಕ್ತರು ಸೇರಿ ಬ್ರಹ್ಮಕಲಶ ಸಮಿತಿ
            ರಚಿಸಿರುವರು. ಕಾರ್ಯೋನ್ಮುಖರಾದ ಸಮಿತಿಯವರು ವಾಸ್ತು ತಜ್ಞರನ್ನು ಸಂಪರ್ಕಿಸಿ ,
            ಗರ್ಭಗುಡಿ, ಅಂಬಲಗಳು , ಪರಿವಾರ ದೈವಗಳ ಗುಡಿ, ತೀರ್ಥದ ಕೆರೆ ನಿರ್ಮಾಣ , ಭೋಜನ
            ಶಾಲೆ ಮತ್ತು ದೇವಸ್ಥಾನದ ಸಂಪರ್ಕ ರಸ್ತೆ ನಿರ್ಮಾಣಕ್ಕಿ ಬೇಕಾದ ನೀಲಿ ನಕ್ಷೆಗಳನ್ನು
            ಪಡೆಯಲಾಗಿದೆ. ಇದರಂತೆ ಪ್ರಕೃತ ಅಂದಾಜು ವೆಚ್ಚ ರೂಪಾಯಿ 2 ಕೋಟಿ 70 ಲಕ್ಷ
            ಬರಬಹುದೆಂದು ಅಂದಾಜಿಸಲಾಗಿದೆ . ಜೀರ್ಣೋದ್ಧಾರ ಸಮಿತಿಯವರು ವಿಳಂಬ ಮಾಡದೆ ಅಂದಾಜು
            9 ಲಕ್ಷ ವೆಚ್ಚದಲ್ಲಿ ಸಂಪರ್ಕ ರಸ್ತೆಯನ್ನು ಪೂರ್ಣಗೊಳಿಸಿದ್ದಾರೆ. ಮುಂದೆ
            ಜೀರ್ಣೋದ್ಧಾರ ಕಾರ್ಯಗಳಿಗೆ, ಬ್ರಹ್ಮಕಲಶದ ಖರ್ಚು ವೆಚ್ಚಗಳಿಗೆ ಸಹೃದಯ ಭಜಕ
            ಭಕ್ತರನ್ನು ಭಿನ್ನವಿಸುವುದೆಂದು ಜೀರ್ಣೋದ್ಧಾರ ಸಮಿತಿಯವರು ನಿಶ್ಚಯಿಸಿರುತ್ತಾರೆ.
            ಈ ದೇವಾಲಯವು ಈಗಿನ ಪ್ರಸ್ತುತ ಕಾಲದಲ್ಲಿ ವಂಶಸ್ಥರು, ಎಲ್ಲಾ ಮಾಜಿ ಒಕ್ಕಲುಗಳು,
            ಊರ-ಪರಊರ ಎಲ್ಲಾ ಆಸ್ತಿಕ ಬಂಧುಗಳಿಗೆ ಸೇರಿದೆ. ಆ ಪ್ರಯುಕ್ತ ಉದಾರ ದಾನಿಗಳು ಕೊಡುವ
            ಧನ, ವಸ್ತುಗಳು,ಮರ ಇತ್ಯಾದಿ ಯಾವ ರೂಪದಿಂದಲು ಕೊಡುವ ಸಹಾಯವನ್ನು
            ಸ್ವೀಕರಿಸುವುದಾಗಿಯೂ ಪ್ರಾರ್ಥನೆ ಮಾಡಿ ಎಲ್ಲ ಭಗವತ್ ಭಕ್ತರು ಈ ಬೃಹತ್ ಕಾರ್ಯಕ್ಕೆ
            ಸಹಕರಿಸಬೇಕೆಂದು ಮತ್ತೊಮ್ಮೆ ವಿನಂತಿಸುತ್ತೇವೆ. ಶ್ರೀ ಲಕ್ಷ್ಮಿ ವೆಂಕಟರಮಣನ ಕೃಪೆ
            ಎಲ್ಲರ ಮೇಲೆ ಇರಲಿ.
          </p>
          <center>
            <img src={expenses}></img>
          </center>
        </Box>
      </Box>

      <style jsx>{`
        body {
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
            "Helvetica Neue", sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        header {
          background-image: linear-gradient(
            to right,
            rgb(38, 179, 251),
            rgb(249, 178, 0)
          );
          width: 100%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100px;
        }

        header h1 {
          font-size: 2rem;
          color: white;
        }

        .header {
          width: 100%;
        }

        p {
          background-color: rgb(255, 248, 231);
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
        }

        h2 {
          color: rgb(38, 179, 251);
        }

        .bg {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-height: 100vh;
          padding: 20px;
          background-color: rgb(255, 248, 231);
        }

        .MuiTextField-root {
          margin: 10px 0;
        }
      `}</style>
    </div>
  );
};

export default About;
